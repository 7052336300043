import _ from "lodash"
import { SearchField } from "@dladio/core-ui"
import { useDispatch } from "react-redux"
import React, { useEffect, useImperativeHandle, useRef, useState } from "react"
import { executeMethod, getDocumentList, getStandardDocumentList } from "./actions"


interface IProps {
    label?: string
    id: string
    onChange: any
    options: IOptions
    required?: boolean
    defaultKey?: string | number | null,
    keyValuePair?: { key: string, text: string }
}

interface IOptions {
    type: string
    query: string | { name: string, id?: string }
    filters?: any
    keypair?: boolean
    path?: string
    payload?: any
}

const OnlineSearchField = React.forwardRef(({
    label,
    id,
    options,
    defaultKey,
    required = false,
    onChange,
    keyValuePair = { key: 'key', text: 'text' }
}: IProps, ref: any) => {

    const dispatch = useDispatch()
    const [list, setList] = useState([])
    const [selectedKey, setSelectedKey] = useState<any>()
    const searchFieldRef: any = useRef(null)

    useEffect(() => {
        getRelatedDocumentList(options)
    }, [options])

    useEffect(() => {
        if (defaultKey) getSelectedKey()
    }, [list])

    useImperativeHandle(ref, () => ({
        clearSearch() {
            searchFieldRef.current?.clearFiled()
        }
    }));


    const onSelect = ({ key, text }: any) => {
        onChange(id, key ?? '', text ?? '')
        setSelectedKey(key)
    }

    const getSelectedKey = () => {
        const res: any = list?.find((line: any) => line?.key == defaultKey || line?.text == defaultKey)
        onChange(id, _.get(res, 'key'), _.get(res, 'text'))
        setSelectedKey(_.get(res, 'key'))
    }

    const getRelatedDocumentList = async ({ type, query, filters = [], path = "data", payload }: IOptions) => {
        switch (type) {
            case 'Standard':
                let standardList: any = await dispatch(getStandardDocumentList(query, filters, keyValuePair, path) as any)
                setList(standardList)
                break;
            case 'erp':
                let sapList: any = await dispatch(getDocumentList(query, keyValuePair) as any)
                setList(sapList)
                break;
            case 'method':
                let list: any = await dispatch(executeMethod(query, payload, keyValuePair) as any)
                setList(list)
                break;
        }
    }    

    const textFieldMarkup = (
        <SearchField
            ref={searchFieldRef}
            required={required}
            label={label}
            options={list}
            defaultValue={defaultKey}
            onChange={onSelect}
        />
    )
    return (<>
        {textFieldMarkup}
    </>)
})

export default React.memo(OnlineSearchField)