import _ from "lodash"
import { useToast } from "@chakra-ui/react"
import { Box, Stack } from "@dladio/core-ui"
import VisibleWrapper from "../../../VisibleWrapper/VisibleWrapper"
import { useDispatch } from "react-redux"
import { v4 as uuidv4 } from 'uuid'
import OnlineSearchField from '../../../../../common/OnlineSearchField/OnlineSearchField'
import { useEffect, useRef } from "react"
import { getItemDetailsAction } from "../../../../actions"
import { DefaultButton, PrimaryButton, TextField } from "@fluentui/react"
import { localStoreThunk, useForm, useLocalStore, useEventListener } from "@dladio/hooks"

const { setStore: setPanel } = localStoreThunk('item-history-panel')

const AddSalesOrderLine = () => {

    const toast = useToast()
    const dispatch = useDispatch()
    const { form, formController, setForm } = useForm('add-line')
    const { select, setStore } = useLocalStore('so')
    const searchFiledRef: any = useRef(null)

    let bpInfo = select("bpInfo", select);

    useEventListener("keydown", ({ key, ctrlKey }: any) => {
        if (key === 'Enter' && ctrlKey) {
            addLineHandler()
        }
    })

    useEffect(() => {
        setForm('quantity', 1)
        setForm('discount', 0)
    }, [bpInfo("CardCode")])


    const itemSelectionHandler = (id: any, text: any) => {
        setStore('selectedPreviousItem', null)
        dispatch(getItemDetailsAction({ item: text, price_list: bpInfo("PriceList") }) as any)
    }

    const clearLines = () => {
        setForm('quantity', 1)
        setForm('discount', 0)
        setStore('soLine', null)
        searchFiledRef.current?.clearSearch()
    }

    const addLineHandler = () => {

        if (bpInfo("PriceList")) {


            if (!_.isEmpty(select("soLine")) && _.get(form, 'quantity') >= 1) {

                let id = uuidv4();
                let soOrderLine = {
                    ...select("soLine"),
                    quantity: _.get(form, 'quantity') ? Math.abs(Math.trunc(_.get(form, 'quantity'))) : 1,
                    discount: _.get(form, 'discount') ?? 0,
                    id
                }

                let soOrderLines = select("soLines") ? [soOrderLine, ...select("soLines")] : [soOrderLine]

                setStore('soLines', _.filter(soOrderLines, function (line) {
                    return line
                }))

                clearLines()

            } else {
                // dispatch(messageAction.show({ title: 'Please select a item!', type: MessageBarType.error }))
                toast({
                    title: 'Please select an item!',
                    status: 'error',
                    duration: 2500,
                    isClosable: true,
                })
            }

        } else {
            // dispatch(messageAction.show({ title: 'The price list is empty!', type: MessageBarType.error }))
            toast({
                title: 'The price list is empty!',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })

        }
    }

    const openPanel = () => {
        dispatch(setPanel('isOpen', true) as any)
    }

    return (
        <>
            <VisibleWrapper>
                <Box title={"Add Sales Order Lines"}>
                    <OnlineSearchField
                        ref={searchFiledRef}
                        keyValuePair={{ key: "ItemCode", text: "ItemName" }}
                        defaultKey={select('selectedPreviousItem')}
                        label={"Item Selection"}
                        id={'item'}
                        onChange={itemSelectionHandler}
                        options={{
                            type: "erp",
                            query: { name: '65096ad5b0', id: '' }
                        }}
                    />
                    <Stack className={"gap-2"}>
                        <TextField
                            type={"number"}
                            // componentRef={quantityRef}
                            value={_.get(form, 'quantity')}
                            name={"quantity"}
                            label={"Quantity"}
                            onChange={formController}
                            className={"w-full me-1"}
                        />
                        <TextField
                            label={"Discount"}
                            name={"discount"}
                            value={_.get(form, 'discount')}
                            className={"w-full ms-1"}
                            type={"number"}
                            onChange={formController}
                        />
                    </Stack>

                    <Stack className={'gap-2 mt-2 justify-end'}>
                        <DefaultButton
                            className={'me-2'}
                            text="Item Order History"
                            onClick={openPanel}
                            allowDisabledFocus
                            checked={true} />
                        <PrimaryButton
                            text="Add Line"
                            onClick={addLineHandler}
                            allowDisabledFocus
                            checked={true} />
                    </Stack>
                </Box>
            </VisibleWrapper>
        </>
    )
}

export default AddSalesOrderLine