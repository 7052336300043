import _ from "lodash"
import { isArray } from "@dladio/utils"
import { useEffect } from "react"
import { CREATE_ADDRESS_KEY } from "../../CreateShippingAddressDialog/CreateShippingAddressDialog"
import { SERVICE, useDerive } from "@dladio/service"
import { Stack, TupleCardLine } from "@dladio/core-ui"
import { useForm, useLocalStore } from "@dladio/hooks"
import { Dropdown, FontIcon, Text } from "@fluentui/react"

const ShipTo = () => {

    const { form, setForm } = useForm()
    const { select } = useLocalStore('so')
    const { setStore: setAddressDialog } = useLocalStore(CREATE_ADDRESS_KEY)

    let bpInfo = select("bpInfo", select);
    let shippingAddress = _.get(form, 'shippingAddress')

    const { value, error } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: '1a2f959d2e', id: bpInfo("CardCode") }
        },
        path: 'message'
    })

    useEffect(() => {
        if (!error) {
            setForm('shippingAddress', _.get(value, [0]))
        }
    }, [value])

    const onChangeHandler = async (key: any, { text }: any) => {

        let address = isArray(value) && value.find((line: any) => line.Address === text)

        if (!_.isEmpty(address)) {
            setForm('shippingAddress', address)

        } else {
            // show alert
        }
    }

    const titleMarkup = (
        <Stack className={'flex justify-between align-middle'}>
            <Stack className={'gap-2 align-middle'}>
                <Text className={'capitalize font-bold py-1'} variant={'medium'}>
                    Ship to
                </Text>
                <FontIcon
                    className="py-1"
                    onClick={() => { setAddressDialog('isOpen', true) }}
                    iconName={'Add'}
                    style={{ fontSize: 12, cursor: 'pointer' }}
                />
            </Stack>
            <Dropdown
                className={"w-[200px] py-1"}
                defaultSelectedKey={_.get(shippingAddress, ['Address'])}
                onChange={onChangeHandler}
                options={dropdownOptionGenerator(value, { text: "Address", key: "Address" })}
            />
        </Stack>
    )

    return (
        <div className={`px-2 pb-2 bg-[#f0f0f0]  w-full`} >
            <div className={'px-2 pb-2 bg-white rounded-md'}>
                {titleMarkup}
                <TupleCardLine title={'Attention To'} value={_.get(shippingAddress, "Address")} />
                <TupleCardLine title={'Company'} value={_.get(shippingAddress, "Address")} />
                <TupleCardLine title={'Street'} value={_.get(shippingAddress, "Street")} />
                <TupleCardLine title={'City'} value={_.get(shippingAddress, "City")} />
                <TupleCardLine title={'State'} value={_.get(shippingAddress, "State")} />
                <TupleCardLine title={'Postal Code'} value={_.get(shippingAddress, "ZipCode")} />
                <TupleCardLine title={'Country'} value={_.get(shippingAddress, "Country")} />
            </div>
        </div>
    )
}

export default ShipTo

interface IKeyValuePair {
    key?: string,
    text: string | number
}

const dropdownOptionGenerator = (
    data: Array<Object> = [],
    keyValuePair: IKeyValuePair = {
        key: "key",
        text: "text"
    }) => {

    if (data?.length > 0) {
        return _.map(data, (line, key) => (
            {
                key: keyValuePair?.key ? _.get(line, keyValuePair.key) : key,
                text: _.get(line, keyValuePair.text)
            }
        ))

    }
    return []
}