import VisibleWrapper from "../VisibleWrapper/VisibleWrapper";
import { useLocalStore } from "@dladio/hooks";
import { TupleCard, TupleCardLine } from "@dladio/core-ui"

const GradesAndStatus = () => {

    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    return (
        <>
            <VisibleWrapper>
                <TupleCard title='Grades & Statuses'>
                    <TupleCardLine title={'Invoice Status'} value={`OVERDUE-${bpInfo("OverdueInvoiceCount")}`} />
                    <TupleCardLine title={'Overdue Amount'} value={`${bpInfo("Currency")} ${bpInfo("OverdueInvoiceValue")}`} />
                    <TupleCardLine title={'Credit Limit'} value={`${bpInfo("Currency")} ${bpInfo("CreditLimit")}`} />
                    <TupleCardLine title={'Price List'} value={bpInfo("PriceListName")} />
                    <TupleCardLine title={'Order Processing Fee'} value={bpInfo("IsOrderProcessingFeeApplied") ? "Applied" : "Not Applied"} />
                </TupleCard>
            </VisibleWrapper>
        </>
    )
}

export default GradesAndStatus