import _ from "lodash"
import { dispatchAction } from "@dladio/app-bridge"
import { localStoreThunk } from "@dladio/hooks"
import { erpMethodApi, SERVICE } from "@dladio/service"

const { setStore } = localStoreThunk("so")

export const getItemDetailsAction = ({ item, price_list }: any) => {
    return async (dispatch: any) => {

        const { error, data } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: 'e5f4313dfa', item, price_list },
        }))

        if (!error && data) {
            dispatch(setStore("soLine", _.get(data, "message")))

        }
    }
}

export const placeOrderAction = (payload: any, toast: any, setDisableSaveAction: any) => {
    return async (dispatch: any) => {

        const { error, data } = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                method: "POST",
                endpoint: "Orders",
                body: payload
            },
        }))

        if (!error && data) {
            // dispatch(messageAction.show({ title: 'Order placed successfully', type: MessageBarType.success }))
            // dispatch(hideLoading())
            dispatchAction({
                action: 'loading',
                payload: false
            })


            toast({
                title: 'Order placed successfully',
                status: 'success',
                duration: 2500,
                isClosable: true,
            })

            dispatchAction({
                action: 'navigate',
                payload: `/app/page/b772b88c26?id=${_.get(data, 'message.DocEntry')}`
            })
            // navigate(`/app/page/b772b88c26?id=${_.get(data, 'message.DocEntry')}`)
        } else {
            // dispatch(messageAction.show({ title: error?.data?.message?.message?.value, type: MessageBarType.error }))
            // dispatch(hideLoading())
            dispatchAction({
                action: 'loading',
                payload: false
            })
            setDisableSaveAction(false)
            toast({
                title: 'Order placed unsuccessful',
                status: 'error',
                duration: 2500,
                isClosable: true,
            })
        }
    }
}