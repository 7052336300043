import _ from "lodash"
import { multiSearch } from "@dladio/utils"
import { SERVICE, useDerive } from "@dladio/service"
import { useEffect, useState } from "react"
import { useAutoFit, useLocalStore } from "@dladio/hooks"
import { DetailsHeader, DetailsList, DetailsListLayoutMode, IColumn, IDetailsHeaderProps, IDetailsListStyles, ISelection, SearchBox, Selection, SelectionMode, Stack } from "@fluentui/react"

const config: any = {
    header_fields: [
        {
            label: 'Item',
            key: "ItemName",
            min_width: 80,
            max_width: 300
        },
        {
            label: 'Last Order Quantity',
            key: 'LastOrderQuantity',
            min_width: 80,
            max_width: 120
        },
        {
            label: 'Last Sales Order date',
            key: 'LastOrderDate',
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Last Sales Order Number',
            key: 'LastOrderNumber',
            min_width: 80,
            max_width: 130
        },
        {
            label: 'Doc',
            key: 'DocEntry',
            min_width: 80,
            max_width: 50
        },
        {
            label: 'Group Name',
            key: 'ItmsGrpNam',
            min_width: 80,
            max_width: 200
        },

    ]
}

const ItemOrderHistoryTable = (
    {
        setPanelDismiss,
        quantityRef
    }: any) => {

    const [configs, setConfigs] = useState<any>(config)
    const { select, setStore } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);

    useEffect(() => {
        setStore('selectedPreviousItem', null)
    }, [bpInfo("CardCode")])

    const getSelectedItem = (line: any) => {        
        setStore('selectedPreviousItem', _.get(line, [0, 'ItemCode']))
        if (!_.isEmpty(line)) setPanelDismiss(false)
    }

    const { value: orderHistoryList } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: "f61d2a03fe",
                id: bpInfo("CardCode")
            }
        }, path: 'message'
    })

    return (
        <>
            <div className={'mx-2'}>
                <OrderHistoryCustomTable
                    onSelectedItem={getSelectedItem}
                    config={configs}
                    quantityRef={quantityRef}
                    model={orderHistoryList ?? []} />
            </div>
        </>
    )
}

const OrderHistoryCustomTable = ({ model, config, onSelectedItem, quantityRef }: any) => {
    let selection: ISelection = new Selection({
        onSelectionChanged: () => {
            onSelectedItem(selection.getSelection())
        }
    })

    const [lines, setLines] = useState<any>([])
    const [columns, setColumns] = useState<any>([]) // need to set data
    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setLines(model)
    }, [model])

    useEffect(() => {
        generateColumns()
    }, [model, config])

    const quantityFocusHandler = () => {
        // quantityRef.current.focus()
        // quantityRef.current.select()
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);
        return fieldContent
    }

    const generateColumns = () => {

        function _onColumnClick(event: React.MouseEvent<HTMLElement>, column: IColumn) {
            let isSortedDescending = column.isSortedDescending;
            if (column.isSorted) {
                isSortedDescending = !isSortedDescending;
            }

            let sortedItems = _copyAndSort(model, column.fieldName!, isSortedDescending);

            let columnList: any = columns.map((col: any) => {
                col.isSorted = col.key === column.key;

                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }

                return col;
            })

            setLines(sortedItems)
            setColumns(columnList)
        }

        let columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
            key: index,
            name: line.label,
            fieldName: line.key,
            page: line.page,
            dataType: line?.type == 'divider' ? null : line.data_type,
            meta: line?.field_meta,
            isResizable: true,
            minWidth: line?.min_width,
            maxWidth: line?.max_width,
            isSortedDescending: false,
            isSorted: false,
            styles: { root: { backgroundColor: '#f0f0f0' } },
            onColumnClick: _onColumnClick
        })
        )
        setColumns(columns)
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const _onRenderDetailHeader: any = (detailsHeaderProps: IDetailsHeaderProps) => {
        if (detailsHeaderProps) {
            return (
                <DetailsHeader
                    {...detailsHeaderProps}
                // onRenderColumnHeaderTooltip={stickyHeader}
                />
            )
        }
        return null
    }
    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    let className = "overflow-auto h-lg"

    // setAutoFit()

    //SEARCH BOX HANDLER
    // const searchTextChangeHandler = (e: any, text: any) => {
    //     const filteredLines = text && model?.length > 0 ? multiSearch(model, text) : model
    //     setLines(filteredLines)
    // }

    return (
        <Stack className={'bg-white rounded-2'}>
            {/*SEARCH BOX*/}
            {/*<div className={'d-flex flex-wrap'}>*/}
            {/*    <SearchBox onChange={searchTextChangeHandler}*/}
            {/*               className="w-25 m-2 rounded-2"*/}
            {/*               placeholder="Search"*/}
            {/*    />*/}
            {/*</div>*/}
            <div className={'d-flex flex-wrap'}>
                <SearchBox onChange={searchTextChangeHandler} className="w-[280px] m-2 rounded-2" placeholder="Search" />
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={selection}
                    compact={true}
                    styles={gridStyles}
                    onActiveItemChanged={quantityFocusHandler}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderDetailsHeader={_onRenderDetailHeader}
                    // onRenderRow={_onRenderRow}
                    isHeaderVisible={true}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.single}
                />
                {/* <NoDataFound visible={(lines?.length == 0)}/> */}
            </div>
        </Stack>
    )
}
const gridStyles: Partial<IDetailsListStyles> = {
    root: {
        overflowX: 'scroll',
        selectors: {
            '& [role=grid]': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                // height: ,
            },
        }
    },
    headerWrapper: {
        flex: '0 0 auto'
    },
    contentWrapper: {
        flex: '1 1 auto',
        overflowY: 'auto',
        overflowX: 'hidden',
    }
};

export default ItemOrderHistoryTable