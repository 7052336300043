import { erpMethodApi, erpResourceApi, SERVICE } from "@dladio/service";
import _ from "lodash";

interface IKeyValuePair {
    key: string,
    text: string
}

export const getDocumentList = (model: any, keyValuePair?: any) => {
    return async (dispatch: any) => {
        let { data } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method: SERVICE.EXEC_QUERY_METHOD,
            body: model
        }));
        let list = keyValuePair ? data?.message.map((line: any) => ({
            key: line[keyValuePair.key],
            text: line[keyValuePair.text]
        })) : data?.message
        return list ?? []
    }
}

export const getStandardDocumentList = (model: any, filters: any, keyValuePair: IKeyValuePair, path = 'data') => {
    return async (dispatch: any) => {
        let { data } = await dispatch(erpResourceApi.endpoints.list.initiate({
            method: model,
            fields: ["*"],
            filters: JSON.stringify(filters)
        }));
        return _.get(data, path).map((line: any) => ({
            key: line[keyValuePair.key],
            text: line[keyValuePair.text]
        })) ?? []
    }
}

export const executeMethod = (method: any, payload: any, keyValuePair?: any) => {
    return async (dispatch: any) => {
        let { data } = await dispatch(erpMethodApi.endpoints.execMethodApi.initiate({
            method,
            body: payload
        }));
        let list = keyValuePair ? data?.message.map((line: any) => ({
            key: line[keyValuePair.key],
            text: line[keyValuePair.text]
        })) : data?.message
        return list ?? []
    }
}