import _ from "lodash"
import { DeleteIcon } from "@chakra-ui/icons"
import { useDispatch } from "react-redux"
import { multiSearch } from "@dladio/utils"
import { showConfirmDialog } from "../../../../../../store/reducers/confirmationDialog"
import { useEffect, useState } from "react"
import { useAutoFit, useLocalStore } from "@dladio/hooks"
import { DetailsList, DetailsListLayoutMode, IColumn, ISelection, SearchBox, Selection, SelectionMode, Text } from "@fluentui/react"

const CustomSalesOrderLineTable = ({ model, config }: any) => {

    const dispatch = useDispatch()
    const { model: store, select, setStore } = useLocalStore('so')

    let selection: ISelection = new Selection()

    const [lines, setLines] = useState<any[]>([])
    const [ref, setAutoFit] = useAutoFit()

    let soLines = _.get(store, "soLines")
    let bpInfo = select("bpInfo", select);
    const currency = bpInfo("Currency")

    useEffect(() => {
        setOrderLines()
        setStore("finalSoLines", model)
    }, [JSON.stringify(model)])

    const setOrderLines = () => {
        let linesWithCurrency = model?.map((line: any) => {
            return {
                ...line,
                Price: `${currency} ${line.Price.toFixed(2)}`,
                discount: `${line.discount}%`,
                discountedAmount: `${currency} ${line.discountedAmount.toFixed(2)}`,
                lineTotal: `${currency} ${line.lineTotal.toFixed(2)}`,
                gst: `${currency} ${line.gst.toFixed(2)}`,
            }
        })

        setLines(linesWithCurrency)
    }

    const lineRemoveHandler = (itemCode: any, id: string) => {

        dispatch(showConfirmDialog({
            title: 'Confirmation Required!',
            subtitle: `Are you sure you want to remove ${itemCode} item?`,
            onConfirm: () => {
                const filteredFinalSoLines = _.filter(model, function (line) {
                    return line.id != id
                })

                const filteredRowSoLines = _.filter(soLines, function (line) {
                    return line.id != id
                })

                setLines(filteredFinalSoLines)

                setStore("finalSoLines", filteredFinalSoLines)
                setStore("soLines", filteredRowSoLines)
            }
        }))
    }

    const removeAllSOLines = () => {
        dispatch(showConfirmDialog({
            title: 'Confirmation Required!',
            subtitle: `Are you sure you want to remove all sales order lines?`,
            onConfirm: () => {
                setStore("finalSoLines", [])
                setStore("soLines", [])
            }
        }))
    }

    const onRenderItemColumn = (model: any, index: any, column: any) => {
        const fieldContent = _.get(model, column.fieldName);
        const { ItemCode, id } = model

        if (column.fieldName == 'action') {
            return <DeleteIcon
                onClick={() => {
                    lineRemoveHandler(ItemCode, id)
                }}
                cursor={"pointer"} w={3} h={3} />
        }

        if (column.fieldName == 'StockOnHand') {
            switch (true) {
                case fieldContent > 0:
                    return <Text className={"bg-green-100 text-green-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"}>{`In Stock ${fieldContent}`}</Text>
                    break;

                case fieldContent == 0:
                    return <Text className={"bg-red-100 text-red-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded"}>Out of Stock</Text>
            }
        }

        return fieldContent
    }

    const columns: IColumn[] = config.header_fields.map((line: any, index: any) => ({
        key: index,
        name: line.label,
        fieldName: line.key,
        page: line.page,
        dataType: line?.type == 'divider' ? null : line.data_type,
        meta: line?.field_meta,
        isResizable: true,
        minWidth: line?.min_width,
        maxWidth: line?.max_width,
        styles: { root: { backgroundColor: '#f0f0f0' } }
    })
    )

    let className = "overflow-auto h-[250px]"

    // setAutoFit()

    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && model.length > 0 ? multiSearch(model, text) : model
        setLines(filteredLines)
    }

    return (
        <>
            <div className="flex justify-end align-middle">
                {lines?.length > 0 &&
                    <DeleteIcon onClick={removeAllSOLines} cursor={"pointer"} me={4} mt={4} w={4} h={4} />}
            </div>

            <div className={'d-flex flex-wrap'}>
                <SearchBox onChange={searchTextChangeHandler}
                    className="w-1/4 m-2 rounded-2"
                    placeholder="Search"
                />
            </div>

            <div ref={ref} className={className}>
                <DetailsList
                    items={lines}
                    columns={columns}
                    selection={selection}
                    compact={true}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    // onRenderRow={_onRenderRow}
                    isHeaderVisible={true}

                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={SelectionMode.none}
                />
                {/* <NoDataFound visible={(lines?.length == 0)} /> */}
            </div>
        </>
    )
}

export default CustomSalesOrderLineTable