import _ from "lodash"
import { useForm } from "@dladio/hooks"
import {useEffect} from "react";
import { TextField } from "@fluentui/react"
import { AutoCompleteTextField, Stack } from "@dladio/core-ui"

const OrderInformationForm = () => {

    const { form,setForm, formController } = useForm()

    useEffect(()=>{
        setForm('U_OrderPriority','STANDARD')
    },[])

    return (
        <>
            <Stack className={"gap-2"}>
                <AutoCompleteTextField
                    label={"Order Channel"}
                    required
                    id={'order_channel'}
                    onChange={formController}
                    options={{
                        type: "erp",
                        query: { name: '797867a810', id: '' }
                    }}
                />

                <TextField
                    required={_.get(form, 'order_channel') == 'Email' ? true : false}
                    style={{ width: "184px" }}
                    name={"contact_details"}
                    onChange={formController}
                    label={"Contact Details"} />

                <AutoCompleteTextField
                    label={"Order Priority"}
                    required
                    defaultKey={'STANDARD'}
                    id={'U_OrderPriority'}
                    onChange={formController}
                    options={{
                        type: "erp",
                        query: { name: '968b1c0198' }
                    }}
                />
            </Stack>
        </>
    )
}

export default OrderInformationForm