import { erpMethodApi, SERVICE } from "@dladio/service"

interface IContact {
    Name: string
}

export const createContact = (cardCode: any, contactList: IContact[]) => {
    return async (dispatch: any, getStore: any) => {
        const res = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                "method": "PATCH",
                "endpoint": `BusinessPartners('${cardCode}')`,
                "body": {
                    "ContactEmployees": [
                        ...contactList
                    ]
                }
            }
        }))

        return res
    }
}

export const createAddress = (cardCode: any, addressList: IContact[]) => {
    return async (dispatch: any, getStore: any) => {
        const res = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                "method": "PATCH",
                "endpoint": `BusinessPartners('${cardCode}')`,
                "body": {
                    "BPAddresses": [
                        ...addressList
                    ]
                }
            }
        }))

        return res
    }
}