import _ from "lodash"
import { Box } from "@dladio/core-ui"
import { isArray } from "@dladio/utils"
import { useState } from "react"
import VisibleWrapper from "../VisibleWrapper/VisibleWrapper"
import { useLocalStore } from "@dladio/hooks"
import CustomSalesOrderLineTable from "./components/CustomSalesOrderLineTable/CustomSalesOrderLineTable"

const config: any = {
    header_fields: [
        {
            label: 'Item',
            key: 'ItemCode',
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Description',
            key: 'ItemName',
            min_width: 80,
            max_width: 250
        },
        {
            label: 'Quantity',
            key: 'quantity',
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Price',
            key: 'Price',
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Discount(%)',
            key: 'discount',
            min_width: 80,
            max_width: 100
        },
        {
            label: 'Discounted Amount',
            key: 'discountedAmount',
            min_width: 80,
            max_width: 200
        },
        {
            label: 'Stock',
            key: 'StockOnHand',
            min_width: 80,
            max_width: 200
        },
        {
            label: 'Tax',
            key: 'gst',
            min_width: 80,
            max_width: 200
        },
        {
            label: 'Line Total',
            key: 'lineTotal',
            min_width: 80,
            max_width: 200
        },
        {
            label: 'actions',
            key: 'action',
            min_width: 80,
            max_width: 80,
        },

    ]
}

const SalesOrderLineTable = () => {

    const { select } = useLocalStore('so')
    const [configs, setConfigs] = useState<any>(config)

    const fetchGstFactory = (form: any, path: any[] | string) => {
        const fetchDataModel: any = {
            'S1': 0.1,
            'S2': 0
        }

        return _.get(fetchDataModel, path)
    }

    const lineCalculationFactory = (line: any) => {
        const data = {
            discountedAmount() {
                return (line.Price * line.quantity) * (line.discount / 100)
            },
            lineTotal() {
                return (line.Price * line.quantity) - data.discountedAmount()
            },
            gst() {
                return +(data.lineTotal() * fetchGstFactory(line, [line.GstGroup])).toFixed(2)
            }
        }

        return data;
    }

    const getSoLines = () => {
        const lines = isArray(select('soLines')) && select('soLines')?.map((line: any) => {

            return {
                ...line,
                discountedAmount: lineCalculationFactory(line).discountedAmount(),
                lineTotal: lineCalculationFactory(line).lineTotal(),
                gst: lineCalculationFactory(line).gst()
            }
        })

        return lines ?? []
    }

    return (
        <>
            <VisibleWrapper>
                <Box title="Sales Order Line">
                    <CustomSalesOrderLineTable config={configs} model={getSoLines().length > 0 ? getSoLines() : []} />
                </Box>
            </VisibleWrapper>
        </>
    )
}

export default SalesOrderLineTable