import _ from 'lodash'

const MODES_OF_DELIVERY = [
    {
        "TrnspCode": 1,
        "TrnspName": "A3 Locale Satchel",
        "Price": 0
    },
    {
        "TrnspCode": 2,
        "TrnspName": "A3 National Satchel",
        "Price": 0
    },
    {
        "TrnspCode": 3,
        "TrnspName": "Air Freight",
        "Price": 0
    },
    {
        "TrnspCode": 4,
        "TrnspName": "Australia Post Express",
        "Price": 24
    },
    {
        "TrnspCode": 5,
        "TrnspName": "Australia Post Regular",
        "Price": 8.5
    },
    {
        "TrnspCode": 6,
        "TrnspName": "Black Fast Way  1day",
        "Price": 0
    },
    {
        "TrnspCode": 7,
        "TrnspName": "Capital",
        "Price": 0
    },
    {
        "TrnspCode": 8,
        "TrnspName": "Equity",
        "Price": 0
    },
    {
        "TrnspCode": 9,
        "TrnspName": "Fastway",
        "Price": 0
    },
    {
        "TrnspCode": 10,
        "TrnspName": "Green Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 11,
        "TrnspName": "Grey Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 12,
        "TrnspName": "Lime Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 13,
        "TrnspName": "Orange Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 14,
        "TrnspName": "Pick up / Walk In",
        "Price": 0
    },
    {
        "TrnspCode": 15,
        "TrnspName": "Pink Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 16,
        "TrnspName": "Red Fast Way 1-2 day",
        "Price": 0
    },
    {
        "TrnspCode": 17,
        "TrnspName": "SEA",
        "Price": 0
    },
    {
        "TrnspCode": 18,
        "TrnspName": "Star Track",
        "Price": 0
    },
    {
        "TrnspCode": 19,
        "TrnspName": "Tea Drop Delivery",
        "Price": 0
    },
    {
        "TrnspCode": 20,
        "TrnspName": "White Fast Way",
        "Price": 0
    },
    {
        "TrnspCode": 21,
        "TrnspName": "DHL",
        "Price": 0
    },
    {
        "TrnspCode": 22,
        "TrnspName": "Tea Drop Delivery - Sydney",
        "Price": 0
    },
    {
        "TrnspCode": 24,
        "TrnspName": "Sea Freight - FCL",
        "Price": 0
    },
    {
        "TrnspCode": 25,
        "TrnspName": "Sea Freight - LCL",
        "Price": 0
    },
    {
        "TrnspCode": 26,
        "TrnspName": "Supplier Delivery",
        "Price": 0
    }
]

const getTranspCode = (trnspName: any) => {
    let res = _.find(MODES_OF_DELIVERY, { "TrnspName": trnspName })
    return _.get(res, 'TrnspCode')
}

export { MODES_OF_DELIVERY, getTranspCode }