import _ from "lodash";

const orderSummaryFactory = (finalSoLines: any[]) => {
    return {
        totalBeforeDiscount() {
            return finalSoLines?.reduce((sum: any, curr: any) => sum + (+curr.Price * curr.quantity), 0) ?? 0
        },

        totalDiscount() {
            return finalSoLines?.reduce((sum: any, curr: any) => sum + curr.discountedAmount, 0) ?? 0
        },

        totalTax() {
            return finalSoLines?.reduce((sum: any, curr: any) => sum + curr.gst, 0) ?? 0
        },

        total() {
            return finalSoLines?.reduce((sum: any, curr: any) => sum + curr.lineTotal, 0) ?? 0
        }
    }
}

const additionalExpensesFactory = (modeOfDeliveryList: any[], defaultModeOfDelivery: string) => {

    const res = modeOfDeliveryList?.find((line) => line.TrnspName == defaultModeOfDelivery)

    return {
        getFreight() {
            return _.get(res, "Price") / 1.1
        },

        getTax() {
            return _.get(res, "Price") - this.getFreight()
        },

        getDeliveryCost() {
            return _.get(res, "Price") ?? 0
        }
    }
}

const getTotal = (orderSummary: any, additionalExpenses: any, orderProcessingFee: any) => {
    return {
        getSubTotalBeforeDiscount() {
            return orderSummary.totalBeforeDiscount() + additionalExpenses.getFreight() + orderProcessingFee ?? 0
        },

        getSubTotal() {
            return orderSummary.total() + additionalExpenses.getDeliveryCost() + orderSummary.totalTax() + orderProcessingFee ?? 0
        },

        getTotalTax() {
            return orderSummary.totalTax() + additionalExpenses.getTax() ?? 0
        }
    }
}

const getOrderProcessingFee = (shipAddress: any = {}, isOrderProcessingFreeApplied: any, orderSummary: any, deliveryMode: any) => {
    if (isOrderProcessingFreeApplied && !_.isEmpty(shipAddress)) {

        if (orderSummary.total() > 120) {
            if (deliveryMode == "Australia Post Regular") {
                return 0
            } else {
                return (shipAddress.State == 'WA' || shipAddress.State == 'NT' || shipAddress.State == 'QLD') ? 13.64 : 9.09;
            }

        } else return (shipAddress.State == 'WA' || shipAddress.State == 'NT' || shipAddress.State == 'QLD') ? 13.64 : 9.09;

    } else return 0
}

export { orderSummaryFactory, additionalExpensesFactory, getTotal, getOrderProcessingFee }