import _ from "lodash";
import { getTranspCode } from "../../data/mode-of-delivery";

const SalesOrderRequestFactory = (billAddress: any, shipAddress: any, form: any, cardCode: any, orderProcessingFee: any, additionalExpenses: any, soLines: any) => {

    const getDueDate = () => {
        const date = new Date();
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }

    const getDeliveryModeCode = () => {
        if (!_.get(form, 'mode_of_delivery_trnspCode')) return getTranspCode(_.get(form, 'modes_of_delivery'))
        else return _.get(form, 'mode_of_delivery_trnspCode')
    }

    return {
        "AddressExtension": {
            "BillToBlock": billAddress?.Block,
            "BillToBuilding": billAddress?.AddressName,
            "BillToCity": billAddress?.City,
            "BillToCountry": billAddress?.Country,
            "BillToCounty": billAddress?.County,
            "BillToState": billAddress?.State,
            "BillToStreet": billAddress?.Street,
            "BillToStreetNo": billAddress?.StreetNo,
            "BillToZipCode": billAddress?.ZipCode,
            "ShipToAddressType": shipAddress?.TypeOfAddress,
            "ShipToBlock": shipAddress?.Block,
            "ShipToBuilding": shipAddress?.AddressName,
            "ShipToCity": shipAddress?.City,
            "ShipToCountry": shipAddress?.Country,
            "ShipToCounty": shipAddress?.County,
            "ShipToState": shipAddress?.State,
            "ShipToStreet": shipAddress?.Street,
            "ShipToStreetNo": shipAddress?.StreetNo,
            "ShipToZipCode": shipAddress?.ZipCode,
            "U_AddressToB": billAddress?.U_AddressTo,
            "U_AddressToS": shipAddress?.U_AddressTo,
            "U_CompanyB": billAddress?.U_Company,
            "U_CompanyS": shipAddress?.U_Company
        },
        "CardCode": cardCode,
        "Comments": form?.remarks,
        "ContactPersonCode": form?.contact_person_code,
        "DocDueDate": getDueDate(),
        "DocumentAdditionalExpenses": [
            {
                "DistributionMethod": "aedm_None",
                "ExpenseCode": 2,
                "LineTotal": orderProcessingFee + additionalExpenses?.getFreight(),
                "Remarks": "Order Processing Fee",
                "TaxType": "aext_NormalTax",
                "VatGroup": "S1"
            }
        ],
        "DocumentLines": soLines?.map((line: any) => ({
            "DiscountPercent": line?.discount,
            "ItemCode": line?.ItemCode,
            "ItemDescription": line?.ItemName,
            "Price": line?.Price * line?.quantity,
            "Quantity": line?.quantity,
            "TaxCode": line?.GstGroup,
            "TaxTotal": line?.gst,
            "UnitPrice": line?.Price,
            "WarehouseCode": "01"

        })),
        "DocumentStatus": null,
        "IsInvoiceEmailed": "NO",
        "IsProformaInvoice": "NO",
        "NumAtCard": form?.customer_ref_no,
        "TransportationCode": getDeliveryModeCode(),
        "U_DropShipSupplier": null,
        "U_IsDropShip": "NO",
        "U_OrderChannel": form?.order_channel,
        "U_OrderChannelDetails": form?.contact_details,
        "U_OrderType": "dropship",
        "U_OrderPriority": form?.U_OrderPriority
    }
}

export { SalesOrderRequestFactory }