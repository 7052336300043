import _ from 'lodash'
import { isArray } from '@dladio/utils'
import { useEffect } from 'react'
import { Dropdown, Text } from '@fluentui/react'
import { SERVICE, useDerive } from '@dladio/service'
import { Stack, TupleCardLine } from '@dladio/core-ui'
import { useForm, useLocalStore } from '@dladio/hooks'

const BillTo = () => {


    const { form, setForm } = useForm()
    const { select } = useLocalStore('so')

    let bpInfo = select("bpInfo", select);
    let billingAddress = _.get(form, 'billingAddress')

    const { value, error } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: '13481cb5b9', id: bpInfo("CardCode") }
        },
        path: 'message'
    })

    useEffect(() => {
        if (!error) {
            setForm('billingAddress', _.get(value, [0]))
        }
    }, [value])

    const onChangeHandler = async (key: any, { text }: any) => {

        let address = isArray(value) && value.find((line: any) => line.Address === text)

        if (!_.isEmpty(address)) {
            setForm('billingAddress', address)

        } else {
            // show alert
        }
    }

    const titleMarkup = (
        <Stack className={'flex justify-between align-middle'}>
            <Text className={'capitalize font-bold py-1'} variant={'medium'}>
                Bill to
            </Text>
            <Dropdown
                className={"w-[200px] py-1"}
                defaultSelectedKey={_.get(billingAddress, ['Address'])}
                onChange={onChangeHandler}
                options={dropdownOptionGenerator(value, { text: "Address", key: "Address" })}
            />
        </Stack>
    )

    return (
        <div className={`px-2 pb-2 bg-[#f0f0f0]  w-full`} >
            <div className={'px-2 pb-2 bg-white rounded-md'}>
                {titleMarkup}
                <TupleCardLine title={'Attention To'} value={_.get(billingAddress, "Address")} />
                <TupleCardLine title={'Company'} value={_.get(billingAddress, "Address")} />
                <TupleCardLine title={'Street'} value={_.get(billingAddress, "Street")} />
                <TupleCardLine title={'City'} value={_.get(billingAddress, "City")} />
                <TupleCardLine title={'State'} value={_.get(billingAddress, "State")} />
                <TupleCardLine title={'Postal Code'} value={_.get(billingAddress, "ZipCode")} />
                <TupleCardLine title={'Country'} value={_.get(billingAddress, "Country")} />
            </div>
        </div>
    )
}

export default BillTo

interface IKeyValuePair {
    key?: string,
    text: string | number
}

const dropdownOptionGenerator = (
    data: Array<Object> = [],
    keyValuePair: IKeyValuePair = {
        key: "key",
        text: "text"
    }) => {

    if (data?.length > 0) {
        return _.map(data, (line, key) => (
            {
                key: keyValuePair?.key ? _.get(line, keyValuePair.key) : key,
                text: _.get(line, keyValuePair.text)
            }
        ))

    }
    return []
}